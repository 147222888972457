<template>
  <div>
    <v-container fluid class="accent white--text" v-if="$vuetify.breakpoint.mobile">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="6" md="6" lg="6" align="center">
          <div class="d-flex flex-column justify-space-between align-center">
            <v-img src="@/assets/landing-page/computer1.jpg" max-width="100%" />
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6" align="center">
          <div class="font-weight-bold mb-4 title1" style="line-height: 1">
            CHEGOU PARA VOCÊ A MAIS NOVA SOLUÇÃO PARA OS DESAFIOS DA LGPD.
          </div>
          <div class="font-weight-light subtitle1">
            O eCONSENSO foi desenvolvido para trazer Mais Segurança e Menos
            Burocracia, através de uma Plataforma Online de fácil utilização e
            totalmente intuitiva.
          </div>
          <v-btn
            rounded
            outlined
            large
            dark
            class="mt-5 my-8"
            @click="formDialog.active = true"
          >
            EU QUERO SABER MAIS
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-sheet dark color="accent" v-else>
      <div no-gutters class="pt-16 pa-5">
        <v-container class="d-flex">
          <div class="flex-grow-1">
            <div class="font-weight-bold mb-4 title1" style="line-height: 1">
              CHEGOU PARA VOCÊ A MAIS NOVA SOLUÇÃO PARA OS DESAFIOS DA LGPD.
            </div>
            <div class="font-weight-light subtitle1">
              O eCONSENSO foi desenvolvido para trazer Mais Segurança e Menos
              Burocracia, através de uma Plataforma Online de fácil utilização e
              totalmente intuitiva.
            </div>
            <v-btn
              rounded
              outlined
              large
              dark
              class="mt-5"
              @click="formDialog.active = true"
            >
              EU QUERO SABER MAIS
            </v-btn>
          </div>
          <div>
            <v-img
              src="@/assets/landing-page/computer1.jpg"
              max-width="80%"
            />
          </div>
        </v-container>
      </div>
      <div class="svg-border-waves">
        <v-img src="@/assets/landing-page/borderWaves.svg" />
      </div>
    </v-sheet>

    <v-row>
      <v-col cols="10" offset="1">
        <div
          class="font-weight-bold title1 text-center black--text mt-8"
          style="line-height: 1"
        >
          Você está a apenas alguns passos de trazer mais Facilidades, Segurança
          e Modernização ao seu Negócio, sob às diretrizes da LGPD.
        </div>
      </v-col>
    </v-row>

    <v-container fluid class="mt-4">
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row>
            <v-col
              cols="12"
              sm="4"
              class="text-center"
              v-for="(feature, i) in features"
              :key="i"
            >
              <v-hover v-slot:default="{ hover }">
                <v-card
                  class="card"
                  shaped
                  :elevation="hover ? 10 : 4"
                  :class="{ up: hover }"
                >
                  <v-img
                    :src="feature.img"
                    max-width="100px"
                    class="d-block ml-auto mr-auto"
                    :class="{ 'zoom-efect': hover }"
                  />
                  <h1 class="font-weight-regular" style="line-height: 1">
                    {{ feature.title }}
                  </h1>
                  <div class="font-weight-regular subtitle-1 mt-4">
                    {{ feature.text }}
                  </div>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-row align="center" justify="center">
      <v-col cols="10">
        <div
          align="center"
          class="font-weight-bold title1 black--text mt-8"
          style="line-height: 1"
        >
          Receba diretamente no seu e-mail mais detalhes sobre o eCONSENSO,
          clicando no botão abaixo:
        </div>
      </v-col>
    </v-row>

    <div align="center" class="pt-2 pb-16">
      <v-btn
        rounded
        large
        dark
        class="mt-5 my-10 accent"
        @click="formDialog.active = true"
      >
        <template v-if="!$vuetify.breakpoint.mobile">
          EU QUERO SABER MAIS SOBRE eCONSENSO
        </template>
        <template v-else>
          QUERO SABER MAIS
        </template>
      </v-btn>
    </div>
    <v-dialog
      v-model="formDialog.active"
      max-width="500px"
      :persistent="formDialog.loading"
    >
      <v-card>
        <div align="end">
          <v-btn
            icon
            @click="formDialog.active = false"
            :disabled="formDialog.loading"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-title class="py-0 justify-center">
          <div align="center" class="subtitle1 ma-5 font-weight-bold break">
            Você receberá em seu e-mail mais detalhes sobre o eCONSENSO, além de
            ter acesso à nossa Equipe e, caso tenha interesse, realizar o seu
            TESTE GRATUITO.
          </div>
        </v-card-title>

        <v-card-text class="pt-4">
          <v-form ref="leadsForm">
            <v-text-field
              label="Nome *"
              v-model="formDialog.data.name"
              dense
              class="mt-2"
              :rules="[$rules.required]"
              :disabled="formDialog.loading"
            />
            <v-text-field
              label="E-Mail *"
              v-model="formDialog.data.email"
              dense
              class="mt-2"
              :rules="[$rules.required, $rules.email]"
              :disabled="formDialog.loading"
            />
            <v-text-field
              label="Telefone *"
              v-model="formDialog.data.phone"
              dense
              class="mt-2"
              :rules="[$rules.required, $rules.phone]"
              v-mask="['(##) ####-####', '(##) #####-####']"
              placeholder="(00) 00000-0000"
              :disabled="formDialog.loading"
            />
            <v-text-field
              label="Qual a sua Empresa atualmente?"
              v-model="formDialog.data.company"
              dense
              class="mt-2"
              :disabled="formDialog.loading"
            />
            <v-text-field
              label="E qual a sua posição nessa Empresa?"
              v-model="formDialog.data.company_position"
              dense
              class="mt-2"
              :disabled="formDialog.loading"
            />
            <v-checkbox
              label="Concordo em receber comunicações sobre o eConsenso"
              :disabled="formDialog.loading"
              :rules="[(v) => !!v || 'Você deve concordar para continuar']"
            />
            <p class="text-subtitle-2 grey--text text--darken-1">
              Uma mensagem com mais informações será enviada para seu e-mail.
              Confira também sua caixa de spam.
            </p>
            <div align="center" justify="center">
              <v-btn
                class="my-2"
                block
                large
                color="success"
                :loading="formDialog.loading"
                @click="sendForm()"
              >
                Enviar
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <Footer />
  </div>
</template>

<script>
import Footer from "@/layout/default/Footer.vue";

export default {
  name: "LandingMoreInfo",
  components: {
    Footer,
  },
  data() {
    return {
      formDialog: {
        active: false,
        loading: false,
        data: {
          name: "",
          email: "",
          phone: "",
          company: "",
          company_position: "",
        },
      },
      features: [
        {
          img: require("@/assets/landing-page/plataforma.png"),
          title: "Plataforma Online",
          text: "Acesso aonde estiver, sem a necessidade de instalar programas no seu computador.",
        },
        {
          img: require("@/assets/landing-page/app.png"),
          title: "Aplicativo eCONSENSO",
          text: "Os seus Clientes terão acesso Gratuito a nosso Aplicativo, para responder rapidamente às suas solicitações.",
        },
        {
          img: require("@/assets/landing-page/escudo.png"),
          title: "Adequação à LGPD",
          text: "Todos os processos realizados pelo o eCONSENSO seguem rigorosamente as diretrizes da LGPD.",
        },
      ],
    };
  },
  methods: {
    sendForm() {
      if (this.$refs.leadsForm.validate()) {
        this.formDialog.loading = true;
        const queryParams = Object.entries(this.$route.query).map(([key, val]) => `${key}=${val}`)
        this.$axios
          .post("/lead", {
            ...this.formDialog.data,
            stage: "Educação - Sistema",
            tags: ["LP Saiba Mais", ...queryParams],
          })
          .then(() => {
            this.$showSuccessMessage(
              "Agradecemos pelo seu contato e Interesse.",
              "Em muito breve enviaremos um e-mail com mais detalhes sobre o eCONSENSO. Fique atento, e caso possua interesse estamos disponíveis por Whatsapp/Telefone no número (48) 4042-3534. Até mais!"
            );
            this.$refs.leadsForm.reset();
            this.formDialog.active = false;
          })
          .catch(() => {
            this.$showMessage(
              "error",
              "Ocorreu um erro inesperado ao salvar os dados. Tente novamente ou contate nosso suporte em contato@econsenso.com.br"
            );
          })
          .finally(() => {
            this.formDialog.loading = false;
          });
        this.$refs.leadsForm.reset()
      } else {
        this.$showMessage(
          "warning",
          "Por favor, preencha os campos obrigatórios"
        );
      }
    },
  },
};
</script>


<style scoped>
.break {
  word-break: break-word;
}
.svg-border-waves .v-image {
  height: 3rem;
  width: 100%;
  overflow: hidden;
}
.svg-border-waves img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: -2px;
  z-index: -1;
}
.card {
  height: 100%;
  padding: 20px;
  transition: 0.5s ease-out;
}
.card .v-image {
  margin-bottom: 15px;
  transition: 0.75s;
}
.zoom-efect {
  transform: scale(1.1);
}
.up {
  transform: translateY(-20px);
  transition: 0.5s ease-out;
}
.title1 {
  font-size: 34px;
  font-weight: bold;
}
.subtitle1 {
  font-size: 21px;
}
</style>
